@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

.right-text {
    /* opacity: 0; */
    writing-mode: vertical-rl;
    color: #ffffff;
    bottom: 15vh;
    right: 4.5%;
    position: fixed;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Source Code Pro', monospace;
    letter-spacing: 2px;
    cursor: pointer;
    transition-duration: .25s;
    text-decoration: none;
}

.l1, .l2, .l3, .l4, .l5, .l6, .l7, .l8, .l9, .l10, 
.l11, .l12, .l13, .l14, .l15, .l16, .l17, .l18, .l19, 
.l20, .l21, .l22, .l23, .l24, .l25, .l26, .l27, .l28, .l29, .l30, .l31, .l32 {
    opacity: 0;
}

.right-text:hover {
    color: rgb(16, 233, 168);
    margin-bottom: 3px;
}

.right-line {
    opacity: 0;
    border-left: 3px solid #ffffff;
    position: fixed;
    bottom: 0%;
    right: 4.95%;
    height: 0%
}

/* Tablet Styles */
@media only screen and (max-width: 1024px) {
    .right-text {
        display: none;
    }

    .right-line {
        display: none;
    }
}

/* Smart Phone Styles */
@media only screen and (max-width: 768px) {

}