.logo-container {
    opacity: 1;
    visibility: visible;
}

.svg-logo-big {
    position: fixed;
    padding: 0px;
    height: 50%;
    width: 50%;
    top: 25%;
    left: 25%;
    color: #8892b0;
    cursor: pointer;
    transition: fill .7s ease-out;
}