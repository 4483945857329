html {
    scroll-behavior: smooth;
}

.svg-logo {
    padding: 0px;
    margin: 0px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    transition: fill .7s ease-out;
    fill: rgba(255, 255, 255, 0);
}

.svg-logo:hover {
    fill:rgb(255, 255, 255);
}

.nav {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
    background-color:  #1e273300;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    position: fixed;
    transition-duration: .3s;
}

.nav-logo {
    opacity: 0;
    font-size: 1.6rem;
    margin-left: 45px;
    color: rgb(255, 255, 255);
}

.n1, .n2, .n3, .n4 {
    opacity: 0;
}

ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.nav-menu-item {
    list-style: none;
    margin: 0px 40px;
    transition-duration: .8s;
}

.nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
}

.nav-menu a {
    border: 2px solid rgba(255, 255, 255, 0);
    font-size: 1.5rem;
    text-decoration: none;
    transition-duration: .6s;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 9px;
}

.nav-menu a:hover {
    border-color: white;
    /* color: rgb(16, 233, 168); */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
}

.nav-menu .resume-btn {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 9px;
    margin-left: 15px;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
    padding: 0.50rem 1rem;
    background-color: #ffffff00;
    transition-duration: .3s;
    cursor: pointer;
}

.nav-menu .resume-btn:hover {
    background-color: rgba(92, 92, 92, 0.288);
}

/* Everything below here is for the version of the nav bar that appears while not at the top of the page */

.moving-nav {
    display: flex;
    justify-content: space-between;
    padding: 0.8%;
    background-color:#1e273300;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 100%;
    position: fixed;
    transition-duration: .3s;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.253);
    z-index: 999;
}

.moving-nav .resume-btn {
    border: 2px solid rgb(255, 255, 255);
    margin-left: 0px;
    font-size: 1rem;

}

.moving-nav a {
    font-size: 1rem;
}

.moving-nav h1 {
    font-size: 1.6rem;
}

.moving-nav .nav-menu-item {
    margin: 0px 15px;
}


/* Tablet Styles */
@media only screen and (max-width: 1024px) {
    .moving-nav {
        position: absolute;
        /* display: none; */
        transition-duration: 0;
        box-shadow: none;
    }
}


/* Smart Phone Styles */
@media only screen and (max-width: 768px) {

    .nav {
        width: 105%;
    }

    .nav-menu-item {
        display: none;
    }

    .nav .resume-btn {
        border: 2px solid rgb(255, 255, 255);
        margin-right: 0px;
        font-size: 1rem;
    }
    
    .nav-logo {
        margin-left: 15px;
        color: rgb(255, 255, 255);
    }
    
    .moving-nav .resume-btn {
        margin-left: 65%;
    }
  }