.content {
    max-width: 90%;
    margin: 0 auto;
    line-height: 1.3;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}

.main {
    display: flex;
    flex-flow: column;
}

.intro {
    opacity: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    margin: auto;
    animation: introfade 5s infinite;
}

.intro h1 {
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 1px;
}

.intro h2 {
    color: #ffffff;
    font-size: 3rem;
    margin-bottom: 1.5%;
}

.intro h3 {
    color: #dbdbdb;
    font-size: 3rem;
    margin-bottom: 5%;
}

.introt1 {
    font-size: 5rem;
    /* margin-left: 50vh; */
}

.intro p {
    color: #ffffff;
    max-width: 500px;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 5%;
}

.intro-btn {
    opacity: 0;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 1px;
    width: 13rem;
    margin-top: 3%;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
    padding: 1rem .5rem;
    background-color: #1e273300;
    transition-duration: .3s;
    cursor: pointer;
    word-spacing: 2.5px;
    text-decoration: none;
    text-align: center;
}

.intro-btn:hover {
    background-color: rgba(92, 92, 92, 0.288);
}

.about-container {
    display: flex;
    max-width: 80%;
    min-height: 100vh;
    margin: auto;
}

.about {
    opacity: 1;
    margin-top: 18%;
    width: 100%;
    display: flex;
    flex-flow: column;
}

.about-title-box {
    display: flex;
    align-items: center;
}

.about h1 {
    color: #ffffff;
    font-size: 3rem;
    margin-bottom: 2%;
}

.about p {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 500;
}

.pfp {
    border-radius: 5px;
    margin-top: 90%;
    max-width: 250px;
}

.skills-list {
    color: rgb(0, 255, 179);
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    margin: 20px 0px 0px;
    overflow: hidden;
    list-style: none;
}

.skills-list li {
    margin-bottom: 10px;
    font-size: 1.3rem;
}

/* Projects Section */

.projects-container {
    display: flex;
    min-width: 800px;
    margin: 0 auto;
    text-align: right;
}

.projects-title {
    color: #ffffff;
    font-size: 1.6rem;
    margin-top: 7%;
    left: 40%;
    position: absolute;
}

/* Project 1 */

.projects-1 {
    margin-top: 40%;
    margin-right: 50px;
    margin-bottom: 17%;
    display: flex;
    justify-content: space-between;
}

.project-1-content h3 {
    color: rgb(0, 255, 179);
    font-size: 1rem;
    font-weight: 500;
}

.project-1-content h2 {
    color: #ffffff;
    font-size: 1.3rem;
    margin-bottom: 5%;
}

.project-1-photo {
    border-radius: 5px;
    max-width: 450px;
    filter: blur(1.5px);
}

.project-1-content {
    display: flex;
    flex-flow: column;
    position: absolute;
    max-width: 450px;
    margin-left: 350px;
    margin-top: 15px;
}

.project-1-content p {
    padding: 20px 20px 20px 25px;
    background-color: #2b3744a4;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9rem;
}

.project-1-frameworks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: #ffffff;
    list-style-type: none;
    margin-left: 220px;
    font-weight: 600;
    margin-top: 2.5%;
}

.project-1-frameworks li{
    margin-left: 5%;
    margin-top: 1%;
}

/* Project 2 */

.projects-2 {
    margin-top: 15%;
    margin-right: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.project-2-content h3 {
    color: rgb(0, 255, 179);
    font-size: 0.9rem;
    font-weight: 500;
    text-align: left;
}

.project-2-content h2 {
    font-size: 1.3rem;
    text-align: left;
    color: #ffffff;
    margin-bottom: 5%;
}

.project-2-photo {
    border-radius: 5px;
    max-width: 450px;
    margin-left: 78%;
    filter: blur(1.5px);
}

.project-2-content {
    display: flex;
    flex-flow: column;
    position: absolute;
    max-width: 450px;
    margin-top: 15px;
}

.project-2-content p {
    padding: 20px 20px 20px 25px;
    background-color: #2b3744a4;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9rem;
}

.project-2-frameworks {
    display: flex;
    color: #ffffff;
    list-style-type: none;
    margin-right: 50%;
    font-weight: 600;
    margin-top: 2.5%;
}

.project-2-frameworks li{
    margin-right: 5%;
}

/* Project 3 */

.projects-3 {
    margin-top: 15%;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
}

.project-3-content h3 {
    color: rgb(0, 255, 179);
    font-size: 0.9rem;
    font-weight: 500;
}

.project-3-content h2 {
    text-align: right;
    color: #ffffff;
    margin-bottom: 5%;
}

.project-3-photo {
    border-radius: 5px;
    max-width: 450px;
    filter: blur(1.5px);
}

.project-3-content {
    display: flex;
    flex-flow: column;
    position: absolute;
    max-width: 450px;
    margin-left: 350px;
    margin-top: 15px;
}

.project-3-content p {
    padding: 20px 20px 20px 25px;
    background-color: #2b3744a4;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9rem;
}

.project-3-frameworks {
    display: flex;
    color: white;
    list-style-type: none;
    margin-left: 220px;
    font-weight: 600;
    margin-top: 2.5%;
}

.project-3-frameworks li{
    margin-left: 5%;
}

.project-3-icons {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
}

.project-3-icon-link, .project-3-icon-github {
    color: white;
    font-size: 25px;
    padding: 0 10px;
}

/* Contact Section */

.contact-container {
    display: flex;
    max-width: 700px;
    min-height: 50vh;
    margin: auto;
}

.contact {
    margin-top: 20%;
    margin-right: 50px;
    display: flex;
    flex-flow: column;
    margin-bottom: 140px;
}

.contact h3 {
    color: #ffffff;
    font-size: 3rem;
    margin-bottom: 3%;
}

.contact p {
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 3%;
}

.contact-btn {
    border: 1.5px solid rgb(255, 255, 255);
    border-radius: 2px;
    width: 7.5rem;
    margin-top: 3%;
    font-size: 1rem;
    color: rgb(255, 255, 255);
    padding: 1rem .5rem;
    background-color: #1e273300;
    transition-duration: .3s;
    cursor: pointer;
    word-spacing: 2.5px;
    text-decoration: none;
    text-align: center;
}

.contact-btn:hover {
    background-color: rgba(92, 92, 92, 0.288);
}

.logo-box {
    display: none;
}

.footer-email {
    display: none;
}

/* Animation Stuff */
.introt1, .introt2, .introt3, .introt4, .about1, .about2, .about3, .about4, .about5, .p1, .p2, .p3, .p4, .contact1, .contact2, .contact3, .contact4 {
    opacity: 0;
}



/* Tablet Styles */
@media only screen and (max-width: 1024px) {
    .content {
        margin: 0 auto;
        width: 70%;
    }

    .projects-container {
        display: block;
        min-height: 100vh;
        text-align: center;
        min-width: 80%;
    }

    .projects-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .projects-title {
        text-align: center;
        margin: auto;
        text-align: center;
        font-size: 30px;
        position: inherit;
    }

    .projects-1, .projects-2, .projects-3 {
        width: 100%;
        margin: 20px auto 30px auto;
        flex-direction: column-reverse;
    }

    .project-1-photo, .project-2-photo, .project-3-photo {
        display: block;
        margin: 0 auto;
        border-radius: 5px;
        filter: blur(0);
        width: 100%;
    }

    .project-1-content, .project-2-content, .project-3-content {
        display: flex;
        flex-flow: column;
        position: inherit;
        max-width: 100%;
        margin: 0 auto;
    }

    .project-1-content p, .project-2-content p, .project-3-content p {
        background-color: rgba(15, 46, 63, 0.438);
    }

    .project-1-frameworks, .project-2-frameworks, .project-3-frameworks {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        color: #ffffff;
        list-style-type: none;
        margin-left: 220px;
        font-weight: 400;
        margin: 0 auto;
        text-align: center;
    }

    .project-2-content h3, .project-2-content h2, .project-3-content h3, .project-3-content h2  {
        text-align: center;
        margin: 0 auto;
    }

    .project-2-content h2, .project-3-content h2  {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 5%;
    }

    .project-1-frameworks, .project-2-frameworks, .project-3-frameworks {
        max-width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }

    .project-2-frameworks, .project-3-frameworks {
        flex-wrap: nowrap;
    }

    .project-1-content p, .project-2-content p, .project-3-content p {
        margin-bottom: 10px;
    }

    .footer {
        margin-bottom: 10%;
    }

    .logo-box {
        display: flex;
        justify-content: space-evenly;
        margin-top: 15%;
    }

    .contact {
        margin-bottom: 0px;
    }

    .footer-email {
        display: block;
        margin-top: 10px;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
        color: white;
    }

    .footer-email:hover {
        color: rgb(16, 233, 168);
    }

    .icon-githubmobile, .icon-linkedinmobile, .icon-codepenmobile{
        color: #ffffff;
        font-size: 35px;
        transition-duration: .2s;
    }
    
    .icon-githubmobile:hover, .icon-linkedinmobile:hover, .icon-codepenmobile:hover{
        color: rgb(16, 233, 168);
        font-size: 34px;
    }
}


/* Smart Phone Styles */
@media only screen and (max-width: 768px) {

    body {
        overflow-x: hidden;
        width: 100%;
    }

    .content {
        max-width: 100%;
        width: 85%;
    }

    .intro {
        padding: 0;
    }

    .intro h2 {
        font-size: 3rem;
    }

    .intro h3 {
        font-size: 2rem;
    }

    .about {
        margin-top: 0%;
        margin-right: 0%;
        padding: 0;
        margin-bottom: 10%;
    }

    .about-container{
        flex-flow: column;
        max-width: 100%;
    }

    .pfp {
        margin: auto;
        margin-left: 15%;
    }

    .contact-title {
        margin: auto;
    }

    .contact {
        margin-right: 0px;
        padding: 5%;
        text-align: center;
    }

    .contact-btn {
        margin: auto;
        margin-top: 5%;
    }
  }